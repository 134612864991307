<template>
  <section class="w-100 position-relative p-1">
    <div class="grid-container mb-2">
      <div
        class="grid-item"
        v-for="(image, index) in getImagesContent()"
        :key="index"
      >
        <img :src="image.basic.preview_url" class="img-cover" @click="redirectPost(image.basic.id, data_user.basic.username)"/>
        <div class="container-icon-heart">
          <p class="d-flex align-items-center" v-if="image.metrics.views_count">
            <feather-icon icon="HeartIcon" class="mr-05"></feather-icon>
            <span>{{ getFormat(image.metrics.views_count.value) }}</span>
          </p>
        </div>
      </div>
    </div>

    <div class="grid-analytics mb-2">
      <div v-for="(item, index) in analytics_data" :key="index" class="grid-item box-style-analytics">
        <span class="avenir-medium">{{ item.label }}</span>
        <span class="title-result">{{ item.value }}</span>
      </div>
    </div>

    <div class="box-style-analytics mb-2">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <p class="f-12 m-0">
          <span class="text-uppercase text-blue text-underline cursor-pointer" @click="redirectToSocialNet(data_user.basic.username)">@{{data_user.basic.username }}</span>
          · {{ getFormat(data_user.parent_data.followers) }} <span class="text-uppercase"> {{ $t('campaigns.followers') }}</span>
        </p>
        <b-button variant="outline-secondary-custom-analysis" @click="redirectToSocialNet(data_user.basic.username)" class="outline-secondary-custom-analysis">
          <feather-icon icon="ExternalLinkIcon"></feather-icon>
        </b-button>
      </div>

      <h4>{{ data_user.basic.title }}</h4>
      <p class="avenir-medium border-bottom pb-1">{{ data_user.basic.description }}</p>

      <p class="text-uppercase f-12">{{ $t('search_preview.contentCategory') }}</p>

      <div class="grid-categories">
        <div v-for="(category, index) in data_user.parent_data.categories.all_categories.slice(0, 5)" :key="index" class="avenir-medium d-flex flex-column align-items-center text-center">
          <b-img :src="getIconInterest(category)" class="img-grid-categorie" v-if="getIconInterest(category)"></b-img>
          <b-icon :icon="getCategoryIconSearcher(category)" v-else class="icon-b"></b-icon>
          <span class="mt-1 d-block">
            {{ $te(`instagram.${category}`) ? $t(`instagram.${category}`) : category }}
          </span>
        </div>
      </div>
      <b-button class="blue-button w-100 mt-2" variant="blue-button" @click.once="$emit('get_profile_url')">
        <span v-if="!is_paid || has_expired">
          {{ $t('search_preview.unlockReport') }}
          <b-img :src="require(`@/assets/images/icons/moneda.svg`)" class="icon-coin"></b-img> 1
        </span>
        <span v-else>{{ $t('search_preview.seeFullReport') }}</span>
      </b-button>
    </div>

    <div class="box-style-analytics mb-2">
      <p class="text-uppercase f-12">{{ $t('twitter.engagementRate')}}</p>
      <div class="d-flex mb-1" v-if="data_user.metrics.er.mark !== 'none'">
        <h2 class="m-0">{{ data_user.metrics.er.value.toFixed(2)}}%</h2>
        <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.metrics.er.mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.metrics.er.mark}`).text }}</b-badge>
      </div>
      <h2 v-else>N/D</h2>
      <p class="avenir-medium" v-if="data_user.metrics.er.mark">
        <span v-if="data_user.metrics.er.mark === 'good' || data_user.metrics.er.mark === 'excellent'">{{ $t('search_preview.engagementbest') }} {{ data_user.metrics.er.prc_better }} %</span>
        {{ $t(`search_preview.engagement_nevel.${data_user.metrics.er.mark}`).long}}
      </p>
      <scale-rate-search v-if="data_user.metrics.er.mark && data_user.metrics.er.mark !== 'none'" :mark="data_user.metrics.er.mark"></scale-rate-search>

    </div>


    <div class="box-style-analytics mb-2">
      <div class="d-flex justify-content-between mb-1">
        <p class="text-uppercase f-12 mb-0">{{ $t(`search_preview.infoAvance`)}}</p>
        <b-badge pill class="badge-ia"><b-img :src="require(`@/assets/images/icons/magic.svg`)" class="icon-ia"/>Con IA</b-badge>
      </div>
      <div class="card-analize p-1 mb-1">
          <div class="icon-aling">
            <b-img :src="require(`@/assets/images/icons/stars.svg`)" class="icon-coin"></b-img>
          </div>
        <div>
          <span class="text-gray f-16">{{ $t(`search_preview.accountAnalyzer`)}}</span>
          <p class="text-gray avenir-medium mb-0"> 
            {{ $t(`search_preview.40params`)}}
          </p>
        </div>
      </div>

      <div  class="card-alert p-1 mb-1" @click="$emit('get_profile_url')">
        <div class="icon-aling">
          <b-img :src="require(`@/assets/images/icons/alert-circle.svg`)" class="icon-coin"></b-img>
        </div>
        <div>
          <span class="text-gray f-16">{{ $t(`search_preview.9problem`)}}</span>
          <p class="text-gray avenir-medium mb-0"> 
           {{ $t(`search_preview.considerInfluencer`)}}
          </p>
        </div>
        <div class="icon-aling">
          <span v-if="!is_paid || has_expired">
            <b-img :src="require(`@/assets/images/icons/moneda-black.svg`)" class="icon-coin"></b-img> 1 <b-icon @click="$emit('get_profile_url')" icon="chevron-right"/>
          </span> 
        </div>
      </div>

      <div class="border-top pt-1">
        <p class="text-uppercase f-12 mb-1">{{ $t(`search_preview.whatVerified`) }}</p>
        
        <div class="grid-more-info">
          <div class="avenir-medium d-flex align-items-start gap-2" v-for="p in more_info_avance" :key="p">
            <feather-icon icon="CheckIcon" class="icon-arrows-prices"></feather-icon>
            <p class="m-0">{{ p }}</p>
          </div>
        </div>  
     </div>


      <b-button class="blue-button w-100 mt-2" variant="blue-button" @click.once="$emit('get_profile_url')">
        <span v-if="!is_paid || has_expired">
          {{ $t('search_preview.unlockReport') }}
          <b-img :src="require(`@/assets/images/icons/moneda.svg`)" class="icon-coin"></b-img> 1
        </span>
        <span v-else>{{ $t('search_preview.seeFullReport') }}</span>
      </b-button>
    </div>

    <div class="box-style-analytics mb-2">
      <p class="text-uppercase f-12">{{ $t('search_preview.growthAccount') }}</p>
      <div class="mb-1" v-if="data_user.metrics.subscribers_growth_prc.performance['365d'].mark !== 'none'">
        <div class="d-flex mb-1">
          <h2 class="m-0">{{ data_user.metrics.subscribers_growth_prc.performance['365d'].value.toFixed(2)}}%</h2>
          <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.metrics.subscribers_growth_prc.performance['365d'].mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.metrics.subscribers_growth_prc.performance['365d'].mark}`).text }}</b-badge>
        </div>
        <p class="avenir-medium">
          <span v-if="data_user.metrics.subscribers_growth_prc.performance['365d'].mark === 'average'">{{ $t(`search_preview.suscribers_growth.average`) }}</span>
          <span v-else>{{ $t(`search_preview.suscribers_growth.${data_user.metrics.subscribers_growth_prc.performance['365d'].mark}`) }} {{ data_user.metrics.subscribers_growth_prc.performance['365d'].similar.toFixed(2)  }}%</span>
        </p>
      </div>
      <div v-else>
        <h2>N/D</h2>
        <p class="avenir-medium">{{  $t(`search_preview.suscribers_growth.none`) }}</p>
      </div>
    
      <div class="mt-2" v-if="subscribers_growth_prc_graph">
        <profile-crecimiento :audienceData="{followers_chart: this.subscribers_growth_prc_graph, following_chart: []}" :show_following="false"></profile-crecimiento>
      </div>
    </div>


    <div class="box-style-analytics mb-2">
      <div class="d-flex justify-content-between">
        <p class="text-uppercase f-12">{{ $t('search_preview.priceRendiment') }}</p>
      </div>

      <!-- POST DATA -->
      <div class="d-flex mb-1 flex-wrap" v-if="data_user.features.blogger_prices.data">
        <h2 class="m-0">${{ getFormat(data_user.features.blogger_prices.data.post_price_from) }} - ${{ getFormat(data_user.features.blogger_prices.data.post_price_to) }}</h2>
        <!-- <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.features.blogger_prices.data.reach_mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.features.blogger_prices.data.reach_mark}`).text }}</b-badge> -->
        <p class="avenir-medium d-block w-100 mt-1">
          <span>{{ $t('search_preview.price.a') }} {{ data_user.features.blogger_prices.data.cpm.toFixed(2) }} %</span>
          {{ $t('search_preview.price.cpe')}}.
        </p>
      </div>

      <div v-else class="mb-1">
        <h2>N/D</h2>
        <p class="avenir-medium">{{ $t(`search_preview.price.none`)}}</p>
      </div>

      <div v-if="!Array.isArray(data_user.calculated_metrics.price_affected_by)">
        <p class="text-uppercase f-12 border-top pt-1">{{ $t('search_preview.factorsAffect') }}:</p>
        <div class="avenir-medium d-flex align-items-start" v-if="data_user.calculated_metrics.price_affected_by.country">
          <feather-icon :icon="data_user.calculated_metrics.price_affected_by.country.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
          <p>
            <span>{{ $t(`search_preview.price.country.creator_from`)}} </span>
            <span>{{ data_user.calculated_metrics.price_affected_by.country.data.country }}</span>
            <span>{{ $t(`search_preview.price.country.${data_user.calculated_metrics.price_affected_by.country.affect}`)}} </span>
          </p>
        </div>

        <div class="avenir-medium d-flex align-items-start justify-content-between mb-1" v-if="data_user.calculated_metrics.price_affected_by.quality_audience">
          <feather-icon :icon="data_user.calculated_metrics.price_affected_by.quality_audience.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
          <p><span>{{ $t(`search_preview.price.audienceAuthenticity.${data_user.calculated_metrics.price_affected_by.quality_audience.affect}`)}} </span></p>
        </div>

        <div class="avenir-medium d-flex align-items-start" v-if="data_user.calculated_metrics.price_affected_by.er">
          <feather-icon :icon="data_user.calculated_metrics.price_affected_by.er.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
          <p>
            <span v-if="data_user.calculated_metrics.price_affected_by.er.affect === 'positive'">{{ $t(`search_preview.price.er.positive_first`)}} {{ data_user.calculated_metrics.price_affected_by.er.data.prc_better }}% {{ $t(`search_preview.price.er.positive_second`) }}</span>
            <span v-else>{{ $t(`search_preview.price.er.negative`) }}</span>
          </p>
        </div>

        <div class="avenir-medium d-flex align-items-start" v-if="data_user.calculated_metrics.price_affected_by.ad_post_frequency">
          <feather-icon :icon="data_user.calculated_metrics.price_affected_by.ad_post_frequency.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
          <p><span>{{ $t(`search_preview.price.advertisingPost.${data_user.calculated_metrics.price_affected_by.ad_post_frequency.affect}`)}} </span></p>
        </div>
      </div>

    </div>

    <div class="box-style-analytics mb-2">
      <p class="text-uppercase f-12">{{ $t(`search_preview.mentions180`)}}</p>
      <div class="mb-1" v-if="mentions_user">
        <div class="d-flex mb-1">
          <h2 class="m-0">{{ separatebycomma(mentions_user.summary.ad.posts_count) }}</h2>
          <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.${mentionsCase(mentions_user.summary.ad.posts_count)}`).color}`">
            {{ $t(`search_preview.${mentionsCase(mentions_user.summary.ad.posts_count)}`).mark }}
          </b-badge>
        </div>
        <p class="avenir-medium">
          <span>{{ $t(`search_preview.${mentionsCase(mentions_user.summary.ad.posts_count)}`).long }}</span>
        </p>
      </div>
      <div v-else>
        <h2>N/D</h2>
        <p class="avenir-medium">{{  $t(`search_preview.mentions_text_mark.none`) }}</p>
      </div>

      <b-avatar-group size="44px" class="mt-2">
        <b-avatar v-for="(avatar, index) in mentions_avatares.slice(0, 9)" :key="index" :src="avatar"></b-avatar>
        <b-avatar v-if="mentions_avatares.length > 9" :text="`+${mentions_avatares.length - 9}`" class="backgroud-gray-light-avatar"></b-avatar>
      </b-avatar-group>
  
    </div>

    <div class="box-style-analytics mb-2">
      <p class="text-uppercase f-12">{{ $t(`search_preview.moreInfoReport`)}}...</p>

      <div class="grid-more-info">
        <div class="avenir-medium d-flex align-items-start" v-for="p in more_info" :key="p">
          <feather-icon icon="LockIcon" class="icon-arrows-prices"></feather-icon>
          <p>{{ p }}</p>
        </div>
      </div>

      <b-button class="blue-button w-100 mt-2" variant="blue-button" @click.once="$emit('get_profile_url')">
        <span v-if="!is_paid || has_expired">
          {{ $t('search_preview.unlockReport') }}
          <b-img :src="require(`@/assets/images/icons/moneda.svg`)" class="icon-coin"></b-img> 1
        </span>
        <span v-else>{{ $t('search_preview.seeFullReport') }}</span>
      </b-button>
    </div>

  </section>
</template>
    
<script>
import { getFormat, separatebycomma } from "@/libs/utils/formats";
import { getCategoryIconSearcher, getIconInterest } from "@/libs/utils/icons";
import { getURL } from "@/libs/utils/urls";
import { BButton, BImg, BIcon, BBadge, BAvatar, BAvatarGroup } from "bootstrap-vue";

export default {
  components: {
    BButton,
    BImg,
    BIcon,
    BBadge,
    BAvatar,
    BAvatarGroup,
    scaleRateSearch: () => import('@/views/components/graph/scaleRateSearch.vue'),
    ProfileCrecimiento: () => import('@/views/pages/profile/ProfileCrecimiento.vue'),

  },
  props: ['data_user', 'network', 'is_paid', 'has_expired', 'subscribers_growth_prc_graph', 'mentions_avatares', 'mentions_user'],
  data() {
    return {
      getFormat,
      getIconInterest,
      getCategoryIconSearcher,
      separatebycomma,
      analytics_data: [
        {
          label: 'ER',
          value: `${this.data_user?.metrics?.er?.value.toFixed(2)} %`
        },
        {
          label: this.data_user?.metrics?.views_avg?.value ? this.$t('search_preview.info_tiktok.views90d') : this.$t('search_preview.info_tiktok.views'),
          value: this.data_user?.metrics?.views_avg?.value ? getFormat(this.data_user.metrics.views_avg.value) : this.data_user?.metrics?.views_avg?.performance?.["90d"]?.value ? getFormat(this.data_user.metrics.views_avg.performance["90d"].value) : 0

        },
        {
          label: this.$t('search_preview.info_tiktok.priceEstimation'),
          value: this.data_user?.features?.blogger_prices?.data?.post_price ? getFormat(this.data_user.features.blogger_prices.data.post_price) : 0
        },
      ],
      more_info: [
        this.$t("search_preview.info_twitch.audienceCountries"),
        this.$t("search_preview.info_twitch.ageAndGenderAudience"),
        this.$t("search_preview.info_tiktok.languagesAudience"),
        this.$t("search_preview.info_tiktok.typeAudience"),
        this.$t("search_preview.info_tiktok.autenticAudience"),
        this.$t("search_preview.info_tiktok.hashtags"),
        this.$t("search_preview.info_tiktok.mentionsBrand"),
        this.$t("search_preview.info_tiktok.priceEstimation"),
        this.$t("search_preview.info_tiktok.cmp"),
        `...${this.$t("search_preview.info_tiktok.23more")}`,
      ],
      more_info_avance: [
        this.$t("search_preview.info_tiktok.location"),
        this.$t("search_preview.info_tiktok.likes"),
        this.$t("search_preview.info_tiktok.viralPotential"),
        this.$t("search_preview.info_tiktok.autenticAudience"),
        this.$t("search_preview.info_tiktok.promVideos"),
        this.$t("search_preview.info_tiktok.suscrIncrease"),
        this.$t("search_preview.info_tiktok.likesComments"),
        this.$t("search_preview.info_tiktok.er")
      ],
    };
  },
  methods: {
    getImagesContent() {
      if (
        this.data_user?.features?.last_media?.data &&
        Object.keys(this.data_user.features.last_media.data).length > 0
      ) {
        const array_images = [];
        for (const image_objet in this.data_user.features.last_media.data) {
          array_images.push(
            this.data_user.features.last_media.data[image_objet]
          );
        }
        return array_images.slice(0, 5);
      }
      return [];
    },
    redirectPost(post, username) {
      window.open(`https://www.tiktok.com/@${username}/video/${post}`, '_blank');
    },
    redirectToSocialNet(username) {
      window.open(getURL(this.network, username), "_blank");
    },
    mentionsCase(post_frequency) {
      const ranges = [
        { min: 22, traduction: "mentions_text_mark.frequency" },
        { min: 15, traduction: "mentions_text_mark.optimal_above" },
        { min: 7, traduction: "mentions_text_mark.optimal" },
        { min: 5, traduction: "mentions_text_mark.below_optimal" },
        { min: 0, traduction: "mentions_text_mark.not_frequency" },
      ];

      for (const range of ranges) {
        if (post_frequency >= range.min) {
          return range.traduction;
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.icon-ia {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
.badge-ia {
  color: #592699;
  background-color: #F4EBFF;
}
.icon-aling {
  align-content: space-around;
}
.card-analize {
  background-color: #ECFEF1;
  border-radius: 6px;
  display: flex;
  padding: 20px;
  border: 1px solid #E2E8F0;
  box-shadow: 0 1px 4px #0b14260a;
  gap:16px
}

.card-alert {
  background-color: #FFFBE5;
  border-radius: 6px;
  display: flex;
  padding: 20px;
  border: 1px solid #E2E8F0;
  box-shadow: 0 1px 4px #0b14260a;
  gap: 16px;

  &:hover {
   cursor: pointer;
   border: 1px solid #3e40436b;
  }
}

.grid-container {
  display: grid;
  // background-color: red;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  cursor: pointer;

  .grid-item {
    .container-icon-heart {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 45%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 0, 0, 0.8) 100%
      );
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      display: flex;
      align-items: end;
      p {
        margin-left: 1em;
        color: white;
        font-family: "avenir-medium";
        overflow: hidden;
      }
    }
    background-color: white;
    position: relative;
    .img-cover {
      width: 100%;
      height: 135.5px;
      object-fit: cover;
    }
    &:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;

      .img-cover {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        height: 281px;
      }
    }
    &:nth-child(3) {
      .img-cover {
        border-top-right-radius: 6px;
      }
    }
    &:nth-child(5) {
      .img-cover {
        border-bottom-right-radius: 6px;
      }
    }
  }
}
.f-12 {
  font-size: 12px;
}
.f-16 {
  font-size: 16px;
}
.grid-analytics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .title-result {
    font-size: 18px;
    width: 100%;
    display: block;
  }
}
.badge-nivel {
  font-family: "avenir-medium" !important;
  font-size: 14px;
  margin-left: 0.5em;
  line-height: 1.3;
  font-weight: 500;
}
.icon-coin {
  width: 20px;
  height: 20px;
}
.icon-arrows-prices {
  display: block;
  flex-shrink: 0;
  color: #2FB574;
  background-color: #f5f8fa;
  border-radius: 100%;
  margin-right: 0.5em;
  margin-top: 0.2em;
}
.box-style-analytics {
  border-radius: 6px;
  box-shadow: 0 1px 4px #0b14260a;
  padding: 16px;
  background-color: white;
  border: 1px solid #e2e8f0;
  .grid-inner-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    .inner-box {
      border: 1px solid #e2e8f0;
      border-radius: 6px;
      box-shadow: 0 1px 4px #0b14260a;
      padding: 16px;
    }
  }
  .outline-secondary-custom-analysis {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    padding: 10px;
  }
  .grid-categories {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    @media (max-width: 600px) {
      grid-template-columns: repeat(auto-fit, minmax(70px, 1fr)); 
    }
    .img-grid-categorie {
      width: 24px;
      height: 24px;
    }
    .icon-b {
      width: 24px;
      height: 24px;
    }
  }
  .grid-more-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4);
    gap: 10px;
  }
}
.toggle-button-post-history {
  background-color: #f5f8fa;
  padding: 4px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;

  button {
    background: none !important;
    color: #687385 !important;
    box-shadow: none !important;
    padding: 9px !important;
    border: 1px solid transparent !important;
  }
  .active-button {
    background-color: white !important;
    border: 1px solid #e2e8f0 !important;
    color: #404452;
  }
}
</style>
  <style>
</style>